import {createBrowserHistory} from 'history';

const history = createBrowserHistory({
    forceRefresh: true
});
const axios = require('axios').default;

const queryLink = "https://sungyun-server.ecs.cat-hou.se/api";
const headerConfig = {};
const unknownError = "서버와 연결을 실패했습니다. 나중에 다시 시도해주세요.";

async function sendQuery(feature, header, data) {

    // 회원가입
    if (feature === "register") {
        axios({
            method: "post",
            url: queryLink + "/users",
            headers: {
                ...headerConfig,
                ...header
            },
            data
        })
            .then(function (response) {
                if (response.status === 200) {
                    history.push('/login');
                    alert("인증 메일이 발송되었습니다. 메일함 또는 스팸함을 확인해주세요.");
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const {data, status} = error.response;
                    switch (status) {
                        case 400 :
                            alert("잘못된 요청입니다.");
                            break;
                        case 409 :
                            alert("중복된 이메일입니다.");
                            break;
                        default :
                            alert("알 수 없는 오류가 발생했습니다.");
                            console.error(status);
                            console.error(data);
                    }
                } else {
                    console.error(error);
                    alert(unknownError);
                }
            });
    }
    // 로그인
    else if (feature === "login") {
        axios({
            method: "post",
            url: queryLink + "/auth/login",
            headers: {
                ...headerConfig,
                ...header
            },
            data
        })
            .then(function (response) {
                if (response.status === 200) {
                    localStorage.setItem('token', `Bearer ${response.data.data}`);
                    history.push("/");
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const {data, status} = error.response;
                    if (status === 400) {
                        alert("올바르지 않은 요청입니다. 다시 시도해보세요.");
                    } else if (status === 401) {
                        alert("아이디 또는 비밀번호가 올바르지 않습니다.");
                    } else if (status === 403) {
                        alert("이메일 인증이 완료되지 않았습니다.");
                    } else {
                        alert("알 수 없는 오류가 발생했습니다.");
                        console.error(status);
                        console.error(data);
                    }
                } else {
                    console.error(error);
                    alert(unknownError);
                }
            });
    }
    // 회원정보 수정
    else if (feature === "editUser") {
        axios({
            method: "put",
            url: queryLink + "/users",
            headers: {
                ...headerConfig,
                Authorization: localStorage.getItem("token"),
                ...header
            },
            data
        })
            .then(function (response) {
                if (response.status === 200) {
                    alert("정보 수정이 완료되었습니다.");
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const {data, status} = error.response;
                    switch (status) {
                        case 400 :
                            alert("잘못된 요청입니다.");
                            break;
                        case 401 :
                            alert("기존 비밀번호가 일치하지 않습니다.");
                            break;
                        default :
                            alert("알 수 없는 오류가 발생했습니다.");
                            console.error(status);
                            console.error(data);
                    }
                } else {
                    console.error(error);
                    alert(unknownError);
                }
            });
    } else if (feature === "deleteAccount") {
        axios({
            method: "delete",
            url: queryLink + "/users",
            headers: {
                ...headerConfig,
                Authorization: localStorage.getItem("token"),
                ...header
            },
            data
        })
            .then(function (response) {
                if (response.status === 200) {
                    alert("회원 탈퇴가 완료되었습니다.");
                    localStorage.removeItem("token");
                    history.replace("/");
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const {data, status} = error.response;
                    switch (status) {
                        case 400 :
                            alert("잘못된 요청입니다.");
                            break;
                        case 401 :
                            alert("인증 토큰이 만료되었습니다. 다시 로그인해주세요.");
                            localStorage.removeItem("token");
                            history.replace("/login");
                            break;
                        case 403 :
                            alert("해당 권한이 없습니다. 관리자에게 문의하세요.");
                            break;
                        default :
                            alert("알 수 없는 오류가 발생했습니다.");
                            console.error(status);
                            console.error(data);
                    }
                } else {
                    console.error(error);
                    alert(unknownError);
                }
            });
    }
    // 게시글 읽기 (비회원, 비밀번호)
    else if (feature === "inquiryPassword") {
        axios({
            method: "POST",
            url: queryLink + "/users",
            headers: {
                ...headerConfig,
                Authorization: localStorage.getItem("token"),
                ...header
            },
            data
        })
            .then(function (response) {
                if (response.status === 200) {
                    history.push(`/contact/inquiry/${response.data.inquiryId}`)
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const {data, status} = error.response;
                    switch (status) {
                        case 400 :
                            alert("잘못된 요청입니다.");
                            break;
                        case 401 :
                            alert("이메일 또는 비밀번호가 다릅니다.");
                            break;
                        default :
                            alert("알 수 없는 오류가 발생했습니다.");
                            console.error(status);
                            console.error(data);
                    }
                } else {
                    console.error(error);
                    alert(unknownError);
                }
            });
    }
    // 게시글 작성하기
    else if (feature === "inquiryWrite") {
        axios({
            method: "POST",
            url: queryLink + "/inquiry",
            headers: {
                ...headerConfig,
                ...header
            },
            data
        })
            .then(function (response) {
                if (response.status === 200) {
                    alert("게시글 작성이 완료되었습니다.");
                    history.push(`/contact/inquiry/${response.data.inquiryId}`)
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const {data, status} = error.response;
                    switch (status) {
                        case 400 :
                            alert("잘못된 요청입니다.");
                            break;
                        case 401 :
                            alert("토큰이 만료되었습니다. 다시 로그인해주세요.");
                            localStorage.removeItem("token");
                            history.replace("/login");
                            break;
                        default :
                            alert("알 수 없는 오류가 발생했습니다.");
                            console.error(status);
                            console.error(data);
                    }
                } else {
                    console.error(error);
                    alert(unknownError);
                }
            });
    }
    // 글 삭제
    else if (feature === "InquiryDelete") {
        console.log(header, data);
        axios({
            method: "delete",
            url: queryLink + "/inquiry/" + data.id,
            headers: {
                ...headerConfig,
                ...header
            }
        })
            .then(function (response) {
                if (response.status === 200) {
                    alert("게시글 삭제가 완료되었습니다.");
                    history.push(`/contact/inquiry/`);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const {data, status} = error.response;
                    switch (status) {
                        case 400 :
                            alert("잘못된 요청입니다.");
                            break;
                        case 401 :
                            alert("토큰이 만료되었습니다. 다시 로그인해주세요.");
                            localStorage.removeItem("token");
                            history.replace("/login");
                            break;
                        default :
                            alert("알 수 없는 오류가 발생했습니다.");
                            console.error(status);
                            console.error(data);
                    }
                } else {
                    console.error(error);
                    alert(unknownError);
                }
            });
    }
    // 게시글 수정하기
    else if (feature === "inquiryModify") {
        axios({
            method: "PUT",
            url: queryLink + "/inquiry/" + data.id,
            headers: {
                ...headerConfig,
                ...header
            },
            data
        })
            .then(function (response) {
                if (response.status === 200) {
                    alert("게시글 수정이 완료되었습니다.");
                    history.push(`/contact/inquiry/${data.id}`)
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const {data, status} = error.response;
                    switch (status) {
                        case 400 :
                            alert("잘못된 요청입니다.");
                            break;
                        case 401 :
                            alert("토큰이 만료되었습니다. 다시 로그인해주세요.");
                            localStorage.removeItem("token");
                            history.replace("/login");
                            break;
                        default :
                            alert("알 수 없는 오류가 발생했습니다.");
                            console.error(status);
                            console.error(data);
                    }
                } else {
                    console.error(error);
                    alert(unknownError);
                }
            });
    }
    // 비밀번호 재발급
    else if (feature === "resetPW") {
        axios({
            method: "POST",
            url: queryLink + `/users/api/reset-password`,
            headers: {
                ...headerConfig
            },
            data
        })
            .then(function (response) {
                if (response.status === 200) {
                    alert("임시 비밀번호 발급이 완료되었습니다. 이메일을 확인하세요.");
                    history.push(`/`)
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const {data, status} = error.response;
                    switch (status) {
                        case 400 :
                            alert("잘못된 요청입니다.");
                            break;
                        case 403 :
                            alert("이메일 또는 전화번호가 일치하지 않습니다.");
                            break;
                        default :
                            alert("알 수 없는 오류가 발생했습니다.");
                            console.error(status);
                            console.error(data);
                    }
                } else {
                    console.error(error);
                    alert(unknownError);
                }
            });
    }
}

const handleMyPageError = (error) => {
    if (error.response) {
        const {data, status} = error.response;
        if (status === 403) {
            alert("권한이 없습니다.");
        } else if (status === 401) {
            alert("인증 토큰이 만료되었습니다. 다시 로그인해주세요.");
            localStorage.removeItem("token");
            history.replace("/login");
        } else {
            alert("알 수 없는 오류가 발생했습니다.");
            console.error(status);
            console.error(data);
        }
    } else {
        console.error(error);
        alert("알 수 없는 오류가 발생했습니다.");
    }
};

export {sendQuery, queryLink, handleMyPageError, headerConfig, unknownError};